import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"
import Layout from "../components/Layout/layout"

const Container = styled.div`
    display: flex;
    width: 80%;    
    height: 95%;    
    justify-content: space-between;
`;

const PlaceholderImage = styled.div`
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
`;

const Info = styled.div`
    flex: 2;
    margin-right: 10px;
    margin-left: 10px;
`;

const Heading = styled.div`
  font-size: 32px;    
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 24px;    
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Details = styled.div`  
  font-size: 14px;    
`;

const Recipies = () => {

  const data = useStaticQuery(graphql`
    query {    
        fileName: file(relativePath: { eq: "header-bg1.jpg" }) {
          childImageSharp {       
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
               
            }
          }
        }  
      }
  `)

  return (
    <Layout>
      <Container>
        <PlaceholderImage>
          <Img alt="profile photo" fluid={data.fileName.childImageSharp.fluid} style={{ width: "100%", height: "100%" }} imgStyle={{ objectPosition: 'center' }} />
        </PlaceholderImage>
        <Info>
          <Heading>Recipies</Heading>
          <Title>Details coming soon...</Title>
          <Details></Details>
        </Info>
      </Container>
    </Layout>
  )

}

export default Recipies
